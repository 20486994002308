import { Service } from './service'

const PATH = 'contacts'

class ContactService extends Service {
  getContactDatatable (params = {}) {
    return this.get(PATH + '/get-contacts', params)
  }

  postDataForm (params = {}) {
    return this.post(PATH + '/create-or-update', params)
  }

  callToAction (params = {}) {
    return this.post(PATH + '/action', params)
  }

  updateDataForm (params = {}) {
    return this.post(PATH + '/create-or-update', params)
  }

  deleteDataForm (params = {}) {
    return this.post(PATH + '/delete', params)
  }

  searchByPhoneEmailId (params = {}) {
    return this.get(PATH + '/searchByPhoneEmailId', params)
  }

  getAvailableContactFields () {
    return this.get(PATH + '/available-contact-fields')
  }

  getFiltersData () {
    return this.get(PATH + '/filters-data')
  }

  getContactCountriesByCampaign (params) {
    return this.post(PATH + '/contact-countries-by-campaign', params)
  }
}

export default new ContactService()

