import { Service } from './service'

const PATH = 'audit'

class AuditService extends Service {
  getDataTable (params = {}) {
      return this.get(PATH, params)
  }
}

export default new AuditService()
